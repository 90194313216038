import React, {ChangeEvent, Dispatch, FC, ReactElement, SetStateAction, useEffect, useState} from 'react'
import ym, { YMInitializer } from 'react-yandex-metrika';
import FormModal from '../../model/FormModal'
import InputPhone from '../Input/InputPhone'
import Quiz1 from '../Quiz/Quiz1'
import Quiz2 from '../Quiz/Quiz2'
import Quiz3 from '../Quiz/Quiz3'
import Quiz4 from '../Quiz/Quiz4'
import Quiz5 from '../Quiz/Quiz5'
import styles from './Form.module.css'
import QuizService from '../../service/QuizService'
import TiktokPixel from 'tiktok-pixel';
import ReactGA from 'react-ga';

import viber from '../../static/image/viber.svg'
import telegram from '../../static/image/telegram.svg'
import kp from '../../static/image/kp.png'


import useAnalyticsEventTracker from './Google';

interface WindowTmr extends Window{
    _tmr: any[]; // типизируем _tmr как массив любых элементов
}

declare var window: WindowTmr;
interface FormPorps{
    setVisible: Dispatch<SetStateAction<boolean>>
}

const From: FC<FormPorps> = ({setVisible})=>{

    const [phonePage, setPhonePage] = useState<boolean>(false)
    const [disableButton, setDisableButton] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [phoneValue, setPhoneValue] = useState('')
    const [validPhone, setValidPhone] = useState(false)
    const [checkValidPhone, setCheckValidPhone] = useState(true)
    const [inputValue, setInputValue] = useState(0)
    const [inputMessenger, setInputMessenger] = useState<string>('telegram')
    const [btnText, setBtnText] = useState<string>('Далее')
    const [page, setPage] = useState<number>(1)
    const [answersAmount, setAnswersAmount] = useState<number>(1)
    const [answerNow, serAnswerNow] = useState<number>(1)
    const [check, setCheck] = useState<boolean>(true)
    const [validCheck, setValidCheck] = useState(true)
    const [useBtn, setUseBtn] = useState<boolean>(true)
    const [formModal, setFormModal] = useState<FormModal>(new FormModal(null, null, null, null, null, ''))
    const [formElement, setFormElement] = useState<ReactElement | ReactElement[]>(<Quiz1 setInputValue={setInputValue} serAnswerNow={serAnswerNow} 
        formModal={formModal}/>)

    const YM = getParams('YM') ? [Number(getParams('YM'))] : [93712831];

    useEffect(() => {
        let cookie = ['_fbp'];
        cookie.forEach(item => {
            if (getParams(item)){
                setCookie(item, getParams(item));
            }
        })
    })

    function setCookie(cname: string, cvalue: string | null) {
        const d = new Date();
        d.setTime(d.getTime() + (365*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }


    useEffect(() => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('1297375657722698')
                ReactPixel.pageView()
            })
        let tiktok_pixel = getParams('tiktok_pixel') ?? 'CCFJO0BC77U85D4JCGF0';
        TiktokPixel.init(tiktok_pixel);
        TiktokPixel.pageView();

        ReactGA.initialize(getParams('GA') ?? 'UA-183667182-1');

        window.addEventListener('resize', resize)

    },[])

    function resize(e:UIEvent){
        const width = (e.target as Window).innerWidth;
        if (width > 767){
            setBtnText('Последний шаг');
        } else {
            setBtnText('Далее');
        }
    }




    function swapPage(){
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const gaEventTracker = useAnalyticsEventTracker('form');
            switch(page){
                case 1:
                    setFormElement(<Quiz1 setInputValue={setInputValue} serAnswerNow={serAnswerNow} formModal={formModal}/>);
                break
                case 2:
                    setFormElement(<Quiz2 setInputValue={setInputValue} serAnswerNow={serAnswerNow} formModal={formModal}/>);
                    ym('reachGoal', 'question_1');
                    gaEventTracker('question_1');
                break
                case 3:
                    setFormElement(<Quiz3 setInputValue={setInputValue} serAnswerNow={serAnswerNow} formModal={formModal}/>);
                    ym('reachGoal', 'question_2');
                    gaEventTracker('question_2');
                break
                case 4:
                    setFormElement(<Quiz4 setInputValue={setInputValue} serAnswerNow={serAnswerNow} formModal={formModal}/>);
                    ym('reachGoal', 'question_3');
                    gaEventTracker('question_3');
                break
                case 5:
                    setFormElement(<Quiz5 setInputValue={setInputValue} serAnswerNow={serAnswerNow} formModal={formModal}/>);
                    ym('reachGoal', 'question_4');
                    gaEventTracker('question_4');
                break
                case 6:
                    clickNextPhone();
                    ym('reachGoal', 'question_5');
                    gaEventTracker('kvizform');

                break
               
                default: setFormElement(<div> error</div>)
                break
               
            }
            setUseBtn(true)
      
        
    }

    async function fetchDataForm(){
        setDisableButton(true)
        if(check && validPhone){
            formModal.phone = phoneValue
            const response = await QuizService.sendForm(formModal.formQuiz, formModal.phone, inputMessenger)
            if(response){
                setDisableButton(false)
                import('react-facebook-pixel')
                    .then((x) => x.default)
                    .then((ReactPixel) => {
                        ReactPixel.init('1297375657722698')
                        ReactPixel.track('Lead', 'quiz_finish');
                    })

                ym('reachGoal', 'zayvka')
                TiktokPixel.track('CompleteRegistration');
                setSuccess(true);


                ReactGA.ga('event', 'kvizsuccess');

                window._tmr.push({ type: 'reachGoal', id: 3498247, goal: 'lead'});

                // eslint-disable-next-line react-hooks/rules-of-hooks
                const gaEventTracker = useAnalyticsEventTracker('form');
                gaEventTracker('kvizsuccess');
            }
        } 
        if(!check){
            setValidCheck(false)
            setDisableButton(false)
        } 
        if(!validPhone){
            setCheckValidPhone(false)
            setDisableButton(false)
        }
        
    }

    function newForm(){
        setFormModal(new FormModal(null, null, null, null, null, ''))
        setPhonePage(false)
        setPhoneValue('')
        setValidPhone(false)
        setPage(1)
        setCheck(false)
        setCheckValidPhone(true)
        setUseBtn(true)
        serAnswerNow(1)
        setAnswersAmount(1)
    }

    function clickNextPhone(){
        if(formModal.formQuiz.question1 && formModal.formQuiz.question2 &&
            formModal.formQuiz.question3 && formModal.formQuiz.question4 && formModal.formQuiz.question5){
            setPhonePage(true)
        } else {
            setPage(page-1)
        }
        
    }

    function clickNext(){
        if(page < 5 && page < answersAmount && useBtn){
            setPage(page +1)
        }
        
       
    }

    function clickPrev(){
        if(page > 1 && useBtn){
            setPage(page-1)
        }
    }

    function getParams(key: string){
        let search = window.location.search.replace('?','');

        let params = new URLSearchParams(search);
        return params.get(key)
    }

    function changeAnswer(){
        if(answerNow > answersAmount){
            setAnswersAmount(answerNow)
        }
        setUseBtn(false)
        setInputValue(0);
        setTimeout(() => {
            setPage(answerNow)
            setUseBtn(true)

        }, 800)
        
    }

    function validationCheck(e: boolean){
        setCheck(e)
        if(check){
            setValidCheck(true)
        }
    }
    
    useEffect(() => {
        swapPage()
    },[page])


    useEffect(() => {
        changeAnswer()
    },[inputValue])


    return(

        <div className={styles.wrapperForm}>
            <YMInitializer accounts={YM} options={{defer: true, clickmap: true, trackLinks: true ,accurateTrackBounce: true, webvisor: false}} />
        {!phonePage ?
            <div className={styles.containerForm}>
            <div className={styles.bodyForm}>
               {formElement}
            </div>

            <div className={styles.footerForm}>
                    <button className={styles.footerForm__btnContainer__prevBtn} onClick={() => clickPrev()}>
                        <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 7.5L8.18571 14M1.5 7.5L8.18571 1M1.5 7.5L19.5 7.5" stroke="#B2BBBC" strokeWidth="2" strokeLinecap="round"/>
                        </svg>

                    </button>
                    <button className={`${styles.footerForm__btnContainer__nextBtn} ${page >= answersAmount ? styles.disable : ''}`} onClick={() => clickNext()}>
                        {page !== 5 ? 'Далее'  : btnText }
                        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M18.5 7.5L11.8143 1M18.5 7.5L11.8143 14M18.5 7.5L0.499999 7.5" stroke="white" strokeLinecap="round"/>
                         </svg>
                    </button>
                    <div className={styles.footerForm__info}>
                        <div>Шаг {page} из 5</div>
                    </div>
            </div>
        </div>
    : <div className={styles.phoneContainer}>
                {
                    !success ?
                        <div className={styles.phoneWindow}>
                                <div className={styles.left_block}>
                                <h2>Куда отправить смету?</h2>
                                <h3>Для получения коммерческого предложения заполните форму</h3>
                                <div className={styles.left_kp}>
                                    <img src={kp} alt=""/>
                                </div>
                            </div>
                            <div className={styles.right_block}>
                                <div className={styles.right_block__wrapper}>
                                    <div className={styles.inputContainer}>
                                        <InputPhone
                                            setValidPhone={setValidPhone}
                                            setValuePhone={setPhoneValue}
                                            setCheckValidPhone={setCheckValidPhone}
                                            checkValidPhone={checkValidPhone}
                                        />
                                    </div>
                                    <div className={styles.right_block__select}>
                                        <div className={styles.right_block__select__header}>Куда вам отправить смету</div>
                                        <div className={styles.right_block__select__btn}>
                                            <div>
                                                <input type="radio" name='source' value='telegram' id='telegram' checked={inputMessenger === 'telegram'} onChange={e => setInputMessenger(e.target.value)}/>
                                                <label htmlFor="telegram">
                                                    Telegram
                                                    <img src={telegram} alt=""/>
                                                </label>
                                            </div>
                                            <div>
                                                <input type="radio" name='source' value='viber' id='viber' checked={inputMessenger === 'viber'} onChange={e => setInputMessenger(e.target.value)}/>
                                                <label htmlFor="viber">
                                                    Viber
                                                    <img src={viber} alt=""/>
                                                </label>
                                            </div>

                                        </div>
                                    </div>

                                    <div className={styles.right_block__checkbox}>
                                        <input onChange={(e) => validationCheck(e.target.checked)} checked={check} className={validCheck ? styles.phoneWindow__checkbox__input : styles.phoneWindow__checkbox__input + ' ' + styles._error} type="checkbox"  id='phoneWindowCheckbox'/>
                                        <label htmlFor="phoneWindowCheckbox">
                                            <span className={styles.right_block__checkbox__text}>Я согласен с <span>обработкой персональных данных</span></span>
                                        </label>
                                    </div>

                                    <button className={styles.phoneWindow__btn} onClick={() => fetchDataForm()} disabled={disableButton}>
                                        Получить смету
                                    </button>
                                    <div className={styles.accept_text}>
                                        Ознакомившись с размещенными на сайте Политикой в отношении обработки персональных данных, с Информацией, предоставляемой до получения согласия, и текстом Согласия на обработку персональных данных, Я ДАЮ СОГЛАСИЕ НА ОБРАБОТКУ МОИХ ПЕРСОНАЛЬНЫХ ДАННЫХ в соответствии с указанными в них целями и перечнем персональных данных
                                    </div>
                                </div>
                                <ul className={styles.consent}>

                                    <li>
                                        <a href="https://drive.google.com/file/d/1sAfxYlahTaMObg5vcpI4RFn-ZJBh2AH-/view" target="_blank">
                                            Информация пользователю сайта до получения согласия
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://drive.google.com/file/d/1NYKuR5OzanMEPlTYOaRa_URIhbfSQqey/view" target="_blank">
                                            Согласие пользователя сайта на обработку перс. данных
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                         :
                        <>
                            <div className={styles.success_header}>Спасибо, что оставили заявку!</div>
                            <div className={styles.success_text}>Наш специалист свяжется с вами в ближайшее время</div>
                        </>
                }

    </div>
    }
    </div>
    )
}
export default From