import React, { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react'
import styles from './InputPhone.module.css'
import validationPhone from '../../validation/ValidationPhone'

interface InputPhoneProps{
    setValuePhone: Dispatch<SetStateAction<string>>
    setValidPhone: Dispatch<SetStateAction<boolean>>
    checkValidPhone: boolean
    setCheckValidPhone: Dispatch<SetStateAction<boolean>>
}

const InputPhone: FC<InputPhoneProps> = ({setValuePhone, setValidPhone, checkValidPhone, setCheckValidPhone})=>{
    const [value, setValue] = useState('')

    function phoneMask(e:ChangeEvent<HTMLInputElement>){
        const getInputNumbers = function (input:any) {
            return input.replace(/\D/g, '');
        }
        const phoneInputs = e.target.value
        const nowPhone = []
        const newPhone = []
        for(let i = 0 ; i < phoneInputs.length; i++){

            const numbItem = getInputNumbers(phoneInputs[i])
            if(numbItem !== ''){
            nowPhone.push(numbItem)
            }
        }
        let re = new RegExp("^375");
        if (nowPhone.length<3 || !re.test(nowPhone.join(''))){
            return '+375';
        }

        newPhone.push('+');

        for(let i =0 ; i < nowPhone.length; i++){
            const numbItem = getInputNumbers(nowPhone[i])
            if(numbItem !== '' && i < 12){
                if(i === 3){
                    newPhone.push(' (')
                }
                if(i === 5){
                    newPhone.push(') ')
                }
                if(i === 8){
                    newPhone.push('-')
                }
                if(i === 10){
                    newPhone.push('-')
                }
                newPhone.push(numbItem)
            } else{
                return false
            }

        }

        setCheckValidPhone(true)
        const phoneNumbers = newPhone.map(item => getInputNumbers(item))
        const validPhone = validationPhone(phoneNumbers)

        setValidPhone(validPhone)
        setValue(newPhone.join(''))

        return phoneNumbers.join('')

    }
    function StyleInputValidPhone(){

        return false
    }

    function changeValue(e:ChangeEvent<HTMLInputElement>){
        const newPhone: string | boolean = phoneMask(e)
        if(newPhone){
            setValuePhone(newPhone)
        }

    }

    function focus(e:ChangeEvent<HTMLInputElement>){
        if (!value){
            setValue('+375');
        }
    }

    return(
            <input  className={`${styles.inputPhone} ${!checkValidPhone ? styles.error : ''}`} placeholder='+375 XX YYY-YY-YY' id='phone-input'
            type={'text'} value={value} onChange={(e) => changeValue(e)} onFocus={focus}/>
    )
}
export default InputPhone