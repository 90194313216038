import exp from "constants";
import { IForm } from "./IForm";
type Nullable<T> = T | null;

class FormModal {
    formQuiz: IForm
    phone: string

    constructor(question1: Nullable<number>, question2: Nullable<number>, question3: Nullable<number>, question4: Nullable<number>, question5: Nullable<number>, phone: string){
        this.formQuiz = {
            question1,
            question2,
            question3,
            question4,
            question5,
        }
        this.phone = phone
    }

    

}

export default FormModal