import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import styles from './Quiz1.module.css'
import FormModal from '../../model/FormModal'
import {conf} from '../../const/questions'
interface Quiz1Props {
    formModal: FormModal
    serAnswerNow: Dispatch<SetStateAction<number>>
    setInputValue: Dispatch<SetStateAction<number>>
}

type Nullable<T> = T | null;

const Quiz1: FC<Quiz1Props> = ({formModal, serAnswerNow, setInputValue}) => {

    const [value, setValue] = useState<Nullable<number>>(formModal.formQuiz.question1)
    const [stylesActive, setStylesActive] = useState(formModal.formQuiz.question1)


    function click(e: number) {
        setValue(e)
        setStylesActive(e)
        serAnswerNow(2)
        setInputValue(e)
    }

    useEffect(() => {
        formModal.formQuiz.question1 = value

    }, [value])


    return (
        <div className={styles.quizWrapper}>
            <div className={styles.quizTitle}>Куда хотите установить видеонаблюдение?</div>
            <div className={styles.quizContainer}>
                {
                    conf['question1'].map((question, index) => (
                        <div className={styles.quizItem} key={`question-${index}`}>
                            <div className={stylesActive === index+1 ? styles.quizItem__wrapper_active : styles.quizItem__wrapper}
                                 onClick={() => click(index+1)}>
                                <img className={styles.quizItem__img} src={question.img} alt='image quiz'/>
                                <div className={`${styles.quizItem__point} ${stylesActive === index+1 ? styles.active : ''}`}></div>
                                <div className={styles.quizItem__title}>
                                    {question.text}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default Quiz1