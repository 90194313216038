import { AxiosResponse } from "axios";
import api from "../http/index";
import { IForm } from "../model/IForm";
import { Send } from "../model/Send";
import {conf} from "../const/questions";
export default class QuizService{

    static async sendForm(form: IForm, phone: string, messenger: string): Promise<AxiosResponse<Send, string>>{
        let labels:{[index:string]: string | null} = {};
        let search = window.location.search.replace('?','');
        
        let params = new URLSearchParams(search);
        for (const key of params.keys()) {
            labels[key] = params.get(key);
        }

        const send: Partial<Send> = {};

        for (const key in form) {
            if (form.hasOwnProperty(key) && form[key as keyof IForm] !== null) {
                const value = form[key as keyof IForm] as number;
                send[key as keyof IForm] = conf[key as keyof IForm][value - 1].text;
            }
        }

        const response = await api.post<Send>('/quiz', {form: send, phone, labels, messenger})
        return response
    }
}