import React, { useState } from 'react';
import './App.css';
import From from './components/Form/From';
import MyModal from './components/Modal/MyModal';

function App() {
const [visible, setVisible] = useState<boolean>(true)

  return (
    <div className="App">
      <MyModal 
        visible={visible}
        setVisible={setVisible}
      >
        <From 
          setVisible={setVisible}
        />
      </MyModal>
    </div>
  );
}

export default App;
