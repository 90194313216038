import React, { Dispatch, FC, ReactElement, SetStateAction } from "react";
import styles from "./MyModal.module.css";

interface ModalProps {
  children: ReactElement | ReactElement[];
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

const MyModal: FC<ModalProps> = ({ children, visible, setVisible }) => {

  const rootClasses = [styles.modal];
  if (visible) {
    rootClasses.push(styles.active);
  }
  return (
    <div
      className={rootClasses.join(" ")}
    >
      
      <div className={styles.modalForm} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};
export default MyModal;
