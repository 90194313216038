import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react'
import FormModal from '../../model/FormModal'
import styles from './Quiz2.module.css'
import {conf} from "../../const/questions";

interface Quiz2Props {
    formModal: FormModal
    serAnswerNow: Dispatch<SetStateAction<number>>
    setInputValue: Dispatch<SetStateAction<number>>

}

type Nullable<T> = T | null;

const Quiz2: FC<Quiz2Props> = ({formModal, serAnswerNow, setInputValue}) => {

    const [value, setValue] = useState<Nullable<number>>(formModal.formQuiz.question2)
    const [stylesActive, setStylesActive] = useState(formModal.formQuiz.question2)


    function click(e: number) {
        setValue(e)
        setStylesActive(e)
        serAnswerNow(3)
        setInputValue(e)
    }

    useEffect(() => {
        formModal.formQuiz.question2 = value

    }, [value])

    return (
        <div className={styles.quizWrapper}>
            <div className={styles.quizTitle}>Сколько камер необходимо?</div>
            <div className={styles.quizContainer}>

                {
                    conf['question2'].map((question, index) => (
                        <div className={styles.quizItem} key={`question-${index}`}>
                            <div className={styles.quizItem__wrapper}
                                 onClick={() => click(index+1)}>
                                <div className={styles.quizItem__title}>
                                    <div className={`${styles.quizItem__point} ${stylesActive === index+1 ? styles.active : ''}`}></div>
                                    <div>{question.text}</div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default Quiz2