import private_business from "../static/image/private_business.png";
import private_house from "../static/image/private_house.png";
import state_facility from "../static/image/state_facility.png";
import apartment_building from "../static/image/apartment_building.png";
import another_object from "../static/image/another_object.png";

export const conf = {
    question1:[
        {text: 'Частный бизнес', img: private_business},
        {text: 'Частный дом/Дача', img: private_house},
        {text: 'Гос. объект', img: state_facility},
        {text: 'Многоквартирный дом', img: apartment_building},
        {text: 'Другой объект', img: another_object}
    ],
    question2:[
        {text: '1-2'},
        {text: '3'},
        {text: '4-6'},
        {text: '6-8'},
        {text: '8-16'},
        {text: '16+'},
        {text: 'Не знаю'},
    ],
    question3:[
        {text: 'До 7 дней'},
        {text: 'До 14 дней'},
        {text: 'До 30 дней'},
        {text: 'Не знаю'},
    ],
    question4:[
        {text: 'Запись звука'},
        {text: 'Доступ к камерам с телефона'},
        {text: 'Четкая детализация'},
        {text: 'Не определился'},
    ],
    question5:[
        {text: 'Только оборудование'},
        {text: 'Оборудование и монтаж'},
    ]
}